import React, { useMemo, useState, useContext, useCallback } from "react";

import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useLanguage from "../hooks/useLanguage";
import filterInvert from "../images/filter-icon-invert.svg";
import filterIcon from "../images/filter-icon.svg";
import reset from "../images/cancel.png";
import Filter from "./Filter";
import ProductItem from "./ProductItem";

import ProductsContext from "../hooks/ProductsContext";
import { debounceFunction } from "../helper/debounceFunction";
import { SEARCH_DELAY } from "../consts";

function filterProductsList(productsList, searchValue, filters) {
  const searchString = searchValue.trim().toLowerCase();

  const products = productsList.filter(function (item) {
    return (
      (item.product_name &&
        item.product_name.toLowerCase().includes(searchString)) ||
      (item.internetbeschreibung &&
        item.internetbeschreibung.toLowerCase().includes(searchString)) ||
      (item.internetbeschreibung_en &&
        item.internetbeschreibung_en.toLowerCase().includes(searchString))
    );
  });

  //const products_by_language = products.filter(function (e) {
  //return e.sprache === lang;
  //});*/
  const products_by_language = products
  // .filter(function (e) {
  //   return e.internetbeschreibung !== undefined;
  // });

  return products_by_language.filter((product) => {
    if (filters.length === 0) {
      return true;
    }
    const productCategorie = product.applicationfields.map(
      (category) => category.label || category.productfiltermatcherid
    );
    const matchFilter = filters.find(
      (filter) => productCategorie.indexOf(filter) !== -1
    );
    return !!matchFilter;
  });
}

function filterProductsByCategory(productsList, searchValue, categories) {
  let productCategories = [];

  if (categories.length === 0) {
    return [];
  }

  categories.forEach((category) => {
    let arr = [];
    category.subcategories.forEach((ref) => {
      const num = filterProductsList(productsList, searchValue, [
        ref.filter_text,
      ]);
      arr = [
        ...arr,
        {
          filter_text: ref.filter_text,
          products_length: num.length,
        },
      ];
    });
    productCategories = [
      ...productCategories,
      { filter_header: category.filter_header, subcategories: arr },
    ];
  });

  return productCategories;
}

function ProductSearch({ categories_and_filters }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const navigate = useNavigate();
  const lang = useLanguage();
  const location = useLocation();

  const { productsList } = useContext(ProductsContext);

  const filters = useMemo(
    () => JSON.parse(searchParams.get("filter")) || [],
    [searchParams]
  );
  // let filteredCategories = useMemo(() => JSON.parse(searchParams.get("category")) || [], []);
  const [showFilters, showFiltersSet] = useState(false)

  const handleToggleClick = useCallback(() => {
    showFiltersSet(state => !state)
  }, []);

  const handleInputChange = (event, obj) => {
    const target = event.target;
    const value = target.value;
    // const category = obj.filter_header;

    // if (filteredCategories.indexOf(category) === -1) {
    //   filteredCategories = [...filteredCategories, category];
    // }

    if (target.checked && filters.indexOf(value) === -1) {
      setSearchParams({
        filter: JSON.stringify([...filters, value]),
        // category: JSON.stringify(filteredCategories),
      });
      return;
    }
    if (!target.checked && filters.indexOf(value) !== -1) {
      // filteredCategories = filteredCategories.filter((v) => v !== category);
      setSearchParams({
        filter: JSON.stringify(filters.filter((v) => v !== value)),
        // category: JSON.stringify(filteredCategories),
      });
    }
  };


  const handleSearchQuery = useCallback(
    (nextValue) => {
      if (nextValue) navigate(`${location.pathname}?search=${nextValue}`);
      if (nextValue === "") navigate(`/${lang}/products`);
    },
    [navigate, lang, location.pathname]
  );

  const customDebounce = useMemo(
    () =>
      debounceFunction(
        (nextValue) => handleSearchQuery(nextValue),
        SEARCH_DELAY
      ),
    [handleSearchQuery]
  );

  const handleChange = (event) => {
    const nextValue = event.target.value;
    setSearchValue(nextValue);
    customDebounce(nextValue);
  };

  const handleReset = useCallback(() => {
    setSearchParams({
      filter: JSON.stringify([]),
      category: JSON.stringify([]),
    });
    setSearchValue("");
  }, [setSearchParams]);

  const filteredProducts = filterProductsList(
    productsList,
    searchValue,
    filters
  );

  const productCategoriesList = filterProductsByCategory(
    productsList,
    searchValue,
    categories_and_filters
  );

  return (
    <>
      <div className="flex justify-center block-none-m justify-between-l center w-100-l ">
        <div className="search-input">
          <input
            className=" ml0 ma3 mr0-l pa3 db-ns w-100 w-50-m w-75-l ba"
            type="text"
            onChange={handleChange}
            value={searchValue}
            placeholder="Produkte filtern"
          />
          <Link
            to="/de/products"
            onClick={handleReset}
            className={"dib pv3 ph4 mv3  outline-0 flex btn-reset"}
          >
            <img src={reset} alt="reset" />
          </Link>
        </div>
        <button
          onClick={handleToggleClick}
          className={
            showFilters
              ? "dib pv3 ph4 bg-schuster b br2 mv3  outline-0 flex "
              : "dib pv3 ph4 b bg-schuster-inverted br2 mv3  outline-0 flex"
          }
        >
          <img
            src={showFilters ? filterInvert : filterIcon}
            className=" w1 pr3"
            alt=""
          />
          Filter
        </button>
      </div>
      <h4 className=" tc ">
        {lang === "de" ? "Anzahl Produkte" : "products"}{" "}
        {filteredProducts.length}
      </h4>
      {showFilters ? (
        <Filter
          productCategoriesList={productCategoriesList}
          handleInputChange={handleInputChange}
          filters={filters}
        />
      ) : null}
      <section>
        <div className="flex-l flex-wrap flex-row-l justify-around">
          {filteredProducts.map((product) => (
            <ProductItem
              lang={lang}
              product={product}
              key={product.product_name}
            />
          ))}
        </div>
        {filteredProducts.length === 0 ? (
          <h2 className="tc">{lang === "de" ? "Kein Produkt gefunden" : ""}</h2>
        ) : null}
      </section>
    </>
  );
}

export default ProductSearch;
