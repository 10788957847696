import React from "react";
import Header from "../components/header";
import ContactForm from "../components/ContactForm";
import Markdown from "../components/Markdown";
import KontaktPage from "./KontaktPage.json";
import { Helmet } from "react-helmet";

function Kontakt() {
  const {
    backgroundImage,
    header,
    headerUnderstatement,
    anfahrt_header,
    anfahrt_text,
    page_name,
    header_understatement,
  } = KontaktPage;

  return (
    <>
      <Helmet>
        <title>{page_name}</title>
        <meta name="description" content={header_understatement} />
      </Helmet>
      <div className="lh-copy">
        <Header
          backgroundImage={backgroundImage}
          header={header}
          headerUnderstatement={headerUnderstatement}
        />
        <section className="center ph4 mw8">
          <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
            {anfahrt_header}
          </h1>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.de/maps/place/Schuster+Elektronik+GmbH/@49.5666796,10.9153272,17z/data=!4m5!3m4!1s0x47a2019510698be9:0xaf82008df8a79156!8m2!3d49.5668506!4d10.9155301"
          >
            Google Maps Karte öffnen
          </a>
          <div className="columns1 columns2 ph3 ph0-ns mt4">
            <Markdown className="mv0 text justify-t-c" value={anfahrt_text} />
          </div>
        </section>
        <ContactForm />
      </div>
    </>
  );
}

export default Kontakt;
