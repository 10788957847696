import React, { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import ProductsContext from "../hooks/ProductsContext";
import useLanguage from "../hooks/useLanguage";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { saveAs } from "file-saver";
import Markdown from "../components/Markdown";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};


export default function Details() {
  const lang = useLanguage();
  const { productsList } = useContext(ProductsContext);
  const params = useParams();
  const { productId } = params;

  const productDetails = useMemo(() => {
    return productsList.find(
      (p) => p.urlpart === productId
    );
  }, [productsList, productId]);

  const initialText = useMemo(() => {
    if (!productDetails) {
      return ""
    }
    if (lang === 'de') {
      return `Bitte senden Sie mir Informationen zu Ihrem Produkt "${productDetails.product_name}" zu. \n  \n \n  \nMit freundlichen Grüßen`
    }
    return `Please send me more information about your product "${productDetails.product_name}". \n  \n \n  \nKind Regards`
  }, [productDetails, lang])

  const saveFile = useCallback(() => {
    if (!productDetails) {
      return
    }
    saveAs(productDetails.bedienungsanleitung, `${productDetails.product_name}.pdf`);
  }, [productDetails]);

  const sliderImages = useMemo(() => {
    if (!productDetails) {
      return
    }
    if (productDetails.bild && productDetails.bilder) {
      return [productDetails.bild, ...productDetails?.bilder];
    }
    return [productDetails.bild];
  }, [productDetails]);

  if (!productDetails) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <div style={{ padding: "50px" }}>
          Produkt nicht gefunden: {productId}
        </div>
      </div>
    );
  }

  const {
    internetbeschreibung,
    internetbeschreibung_en,
    product_name,
    similarproducts,

    internetanwendungen,
    internetanwendungen_en,
    internetmerkmale,
    internetmerkmale_en,
    data_sheet,
    bedienungsanleitung,
    applicationfields,
  } = productDetails
  // internetmerkmale_en
  // internetmerkmale
  // internetkurzbeschreibung
  // geratesystembenennung_en
  // internetkurzbeschreibung_en

  const SimilarProductsLength = similarproducts.length > 0;

  return (
    <>
      <Helmet>
        <title>{product_name}</title>
        <meta
          name="description"
          content={lang === 'de' ? internetbeschreibung : internetbeschreibung_en}
        />
      </Helmet>
      <section className="mw8 center ph4">
        <div className="db pt5 pb4 ph3 ph0-l no-underline black ">
          <div className="flex flex-column flex-row-ns pv3  mt4">
            <div className=" pa3 bg-light-gray w-100 center">
              <h2>{product_name}</h2>
              <Markdown
                className="text f-18"
                value={lang === 'de' ? internetanwendungen : internetanwendungen_en}
              />
            </div>
          </div>
        </div>
        <div className="flex-l flex-row-l flex-wrap justify-between mt4 ph3 ph0-l">
          <div className="flex flex-column  mr0 mr2-l w100 w-30-l">
            <Slider {...settings} className="pv3 pl0">
              {sliderImages &&
                sliderImages.map((item) => (
                  <div key={item} className="relative">
                    <img
                      src={item}
                      alt=""
                      style={{ margin: "auto", width: "100%" }}
                    />
                  </div>
                ))}
            </Slider>
          </div>
          <div className="flex flex-column  mr0 mr2-l w100 w-30-l">
            <div>
              <ul className="bg-light-gray pv3 pl0">
                <div className="ph4 ph3-ns mw8 ">
                  <h1 className="tl  f4 f3-ns mb0 pb2">Merkmale</h1>
                </div>
                <Markdown
                  value={lang === "de" ? internetmerkmale : internetmerkmale_en}
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontSize: '14px',
                    color: '#626262'
                  }}
                />
              </ul>
            </div>
          </div>
          <div className="flex flex-column mr0 mr2-l ml2-l  w-30-l">
            <div>
              <small>{data_sheet}</small>
              {bedienungsanleitung ? (
                <button
                  onClick={saveFile}
                  className="db pv3 ph4 bg-schuster-inverted b br2 mv3  outline-0 link tc hover-schuster-inverted-green"
                >
                  {`${product_name} `}
                  (pdf)
                </button>
              ) : null}
              <a
                href="#contact"
                className="db pv3 ph4 bg-schuster-inverted b br2 mv3  outline-0 link  tc hover-schuster-inverted-green"
              >
                Kontaktanfrage
              </a>
              {SimilarProductsLength ? (
                <small className="fw1">Ähnliche Produkte</small>
              ) : null}
              <div>
                {SimilarProductsLength
                  ? similarproducts.map((similarproduct) => (
                    <Link
                      className="db mv3 b link schuster-green "
                      key={`${similarproduct.url}`}
                      to={`/${lang}${similarproduct.url}`}
                    >
                      {similarproduct.title}
                    </Link>
                  ))
                  : null}
              </div>
              <small className="fw1">Anwendungsgebiete</small>
              {applicationfields.map((category) => (
                <Link
                  className="db mv3 b link schuster-green f5"
                  key={category.url}
                  to={`/${lang}${category.url}`}
                >
                  {category.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="justify-t-c ph3 ph0-l">
          {/* <h1 className="tl  f4 f3-ns">{product_name}</h1> */}
          <Markdown
            className="text lh-copy tj"
            value={lang === 'de' ? internetbeschreibung : internetbeschreibung_en}
          />
        </div>
      </section>
      <ContactForm
        initialText={
          initialText
        }
      />
    </>
  );
}
