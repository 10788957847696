import React from "react";
// import { useLocation } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import slugify from "../components/slugify";
import Header from "../components/header";
import Markdown from "../components/Markdown";
import ServicePage from "./ServicePage.json";
import { Helmet } from "react-helmet";

export default function Service() {
  // const { pathname } = useLocation();

  const {
    backgroundImage,
    header,
    headerUnderstatement,
    sections,
    servicepageblock2,
    page_name,
    header_understatement,
  } = ServicePage;

  return (
    <>
      <Helmet>
        <title>{page_name}</title>
        <meta name="description" content={header_understatement} />
      </Helmet>
      <div className="lh-copy">
        <Header
          backgroundImage={backgroundImage}
          header={header}
          headerUnderstatement={headerUnderstatement}
        />
        <section className="mw8 center pb5 mt5" id="service">
          {sections.map((item, index) => (
            <article
              className="pv4 ph3 ph0-l "
              key={index}
              id={`#${slugify(item.category_header)}`}
            >
              <div className="flex flex-column flex-row-ns">
                <div
                  className="pl5-l mb4 mb0-ns w-30 w-20-ns db dn-ns"
                  style={{ margin: "auto" }}
                >
                  <img src={item.category_image} className="db dn-ns" alt="" />
                </div>
                <div
                  className="w-100 w-80-ns ph4 ph5-l tc tl-ns"
                  style={{ margin: "auto" }}
                >
                  <h3
                    className="fw2 f-26 gray-color w-bold"
                    style={{ marginBottom: "0.5rem", marginTop: 0 }}
                  >
                    {item.category_header}
                  </h3>
                  <p className="text mv0 ">{item.category_text}</p>
                </div>
                <div
                  className="pr5-l mb4 mb0-ns w-50 w-20-ns"
                  style={{ margin: "auto" }}
                >
                  <img src={item.category_image} className="dn db-ns" alt="" />
                </div>
              </div>
            </article>
          ))}
        </section>
        <section className="mw8 center pb5 mt5" id="details">
          <Markdown
            value={servicepageblock2}
            style={{ fontWeight: "200" }}
            className="pl5-l text mv0 fw2 gray-color"
          />
        </section>
        <ContactForm />
      </div>
    </>
  );
}
