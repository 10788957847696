import React from "react";
import Header from "../components/header";
import Markdown from "../components/Markdown";
import slugify from "../components/slugify";
import useLanguage from "../hooks/useLanguage";
import CareerPage from "./CareerPage.json";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

function ContentBlock({ header, content, cta }) {
  return (
    <section className="mw8 center pv5 ph4">
      <div id={`#${slugify(header)}`}>
        <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">{header}</h1>
        <div className="columns1 columns2 justify-t-c">
          <p className="mv0 text">{content}</p>
          {cta ? (
            <p
              className="mv0 text"
              style={{ marginTop: "32px", fontWeight: "bold", color: "orange" }}
            >
              <Markdown value={cta} />
            </p>
          ) : null}
        </div>
      </div>
    </section>
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Career() {
  const { lang } = useLanguage();

  const {
    erfahrung_header,
    erfahrung_item,
    backgroundImage,
    header,
    headerUnderstatement,
    job,
    open_jobs_header,
    open_jobs,
    page_name,
    header_understatement,
  } = CareerPage;

  return (
    <>
      <Helmet>
        <title>{page_name}</title>
        <meta name="description" content={header_understatement} />
      </Helmet>
      <div className="lh-copy">
        <Header
          backgroundImage={backgroundImage}
          header={header}
          headerUnderstatement={headerUnderstatement}
        />

        <ContentBlock
          header={lang === 'de' ? job[0].job_header : job[0].job_header_en || job[0].job_header}
          content={job[0].job_description}
          cta={job[0].cta}
        />

        <div id={`#${slugify(open_jobs_header)}`}>
          <section className="mw8 center ph4 pv2">
            <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
              {open_jobs_header}
            </h1>
            <div className="flex flex-row flex-wrap justify-between g-2">
              {open_jobs.map((job, index) => (
                <a
                  href={job.pdf_file}
                  download
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                  className="flex flex-wrap  flex-column items-center  bg-light-gray mt3 w-100 w-40-ns"
                  key={index}
                >
                  <div className="flex-column items-center lh-copy mh2 pa3  bg-light-gray justify-center">
                    <img
                      className=" w4 h4 br-100 db center mb3"
                      src={job.job_image}
                      alt=""
                    />
                    <div className="pl3 flex-auto">
                      <p className=" db gray-color tc f-18 mt0 mb1 b">
                        {job.job_titel}
                      </p>
                      <small className="db fw6 tc link gray-color">
                        {job.job_under_titel}
                      </small>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </section>
        </div>

        <ContentBlock
          header={job[1].job_header}
          content={job[1].job_description}
          cta={job[1].cta}
        />

        <section
          className="mw8 center mb5 ph4"
          id={`#${slugify(erfahrung_header)}`}
        >
          <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
            {erfahrung_header}
          </h1>
          <Slider {...settings} className="pv3 pl0">
            {erfahrung_item.map((erfahrungItem, index) => (
              <article key={index}>
                <div className="pv4 ph3 ph0-l   flex flex-column flex-row-ns">
                  <div className="pr3-ns mb4 mb0-ns w-100 w-40-ns">
                    <img
                      src={erfahrungItem.erfahrung_image}
                      className="db"
                      alt=""
                    />
                  </div>
                  <div className="w-100 w-60-ns ">
                    <p className="text  mv0 ph3">
                      {erfahrungItem.erfahrung_text}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </Slider>
        </section>
      </div>
    </>
  );
}

export default Career;
