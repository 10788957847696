import React from "react";
// import { Link } from "react-router-dom";
import Productsearch from "../components/ProductSearch";
import Header from "../components/header";
import ContactForm from "../components/ContactForm";
import ProductsPage from "./ProductsPage.json";
import { Helmet } from "react-helmet";
// import useLanguage from "../hooks/useLanguage";

export default function Products() {
  // const language = useLanguage();
  const {
    backgroundImage,
    header,
    headerUnderstatement,
    categories_and_filters,
    products_header,
    products_description,
    page_name,
    header_understatement,
  } = ProductsPage;
  return (
    <>
      <Helmet>
        <title>{page_name}</title>
        <meta name="description" content={header_understatement} />
      </Helmet>
      <div>
        <Header
          backgroundImage={backgroundImage}
          header={header}
          headerUnderstatement={headerUnderstatement}
        />
        <section className="mw8 center pa3">
          <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
            {products_header}
          </h1>
          <p className="text  mv0 ">{products_description}</p>
          <Productsearch categories_and_filters={categories_and_filters} />
        </section>
        <ContactForm />
      </div>
    </>
  );
}
