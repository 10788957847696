import React from "react";
import { Link } from "react-router-dom";
import tel from "../images/iphone-white.svg";
import emailicon from "../images/email-white.svg";
import room from "../images/room.svg";
import useLanguage from "../hooks/useLanguage";
import MenuData from "../MenuData.json";

function scrollto() {
  const elmnt = document.getElementById(window.location.hash);
  if (elmnt !== null) {
    elmnt.scrollIntoView(true);
    const yourHeight = 74;
    const scrolledY = window.scrollY;
    if (scrolledY) {
      window.scroll(0, scrolledY - yourHeight);
    }
  }
}

function MenuItem({ lang, item }) {
  return (
    <div className="w-100 w-20-l flex-l flex-row-l tl pa2">
      <div className="flex flex-column tl-l">
        <Link
          to={`/${lang}/${item.item_slug ? `${item.item_slug}/` : ""}`}
          className="b no-underline white-70 dib-l pb3 pt4 ph3 white  footer-header footer-hover"
        >
          {item.item_header}
        </Link>
        {item.subitems
          ? item.subitems.map((subitem, indexing) => (
            <Link
              onClick={() => {
                setTimeout(() => scrollto(), 30);
              }}
              key={indexing}
              to={`/${lang}/${subitem.urlpart ? `${subitem.urlpart}` : ""}`}
              className="f5 no-underline white-70 db-l pb2 ph3 white footer-hover"
            >
              {subitem.title}
            </Link>
          ))
          : null}
      </div>
    </div>
  );
}

function Footer() {
  const lang = useLanguage();
  return (
    <footer className="db">
      <div className="cover bg-left bg-center-l">
        <div className="bg-green-schuster">
          <nav
            className="flex  center justify-between flex-wrap "
            style={{ maxWidth: "74rem" }}
          >
            {MenuData.items
              .filter((i) => !i.footerhide)
              .map((item) => (
                <MenuItem key={item.item_slug} item={item} lang={lang} />
              ))}
          </nav>
          <div
            className="flex flex-column flex-row-l pb3 pt3 center"
            style={{ maxWidth: "74rem" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              className="white link pa3 flex footer-hover"
              href="https://www.xing.com/pages/schuster-elektronik-gmbh"
              title="XING"
            >
              XING
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              className="white link pa3 flex footer-hover"
              href="https://www.linkedin.com/in/armin-rech-3625a065/"
              title="LinkedIn"
            >
              LinkedIn
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              className="white link pa3 flex footer-hover"
              href="https://www.youtube.com/channel/UCnxeUSQ_QLO-MBAAPIPB3Sg"
              title="YouTube"
            >
              YouTube
            </a>
            <a
              className="white link pa3 flex tc footer-hover"
              href="https://www.google.de/maps/place/Schuster+Elektronik+GmbH/@49.5666796,10.9153272,17z/data=!4m5!3m4!1s0x47a2019510698be9:0xaf82008df8a79156!8m2!3d49.5668506!4d10.9155301"
              target="_blank"
              rel="noreferrer"
            >
              <img src={room} className="w1 h1" alt="" />
              <span>
                Peter-Fleischmann-Str 30,
                <br />
                D-91074 Herzogenaurach
              </span>
            </a>
            <a
              className="white link pa3 flex footer-hover"
              href="tel:+499132750440"
            >
              <img src={tel} className="h1 w1 pr1" alt="" />
              +49 (0) 9132 750440
            </a>

            <a
              className="white link pa3 flex footer-hover"
              href={`mailto:sales@schuster-elektronik.de`}
            >
              <img src={emailicon} className="w1 h1 pr1" alt="" />
              sales@schuster-elektronik.de
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
