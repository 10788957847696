import React, { useMemo, createContext } from "react";
import useFetchProducts from "../hooks/useFetchProducts";

const ProductsContext = createContext({
  productsList: [],
});

export function ProductsContextProvider(props) {
  const { data } = useFetchProducts();

  const value = useMemo(
    () => ({
      productsList: data,
    }),
    [data]
  );

  return (
    <ProductsContext.Provider value={value}>
      {props.children}
    </ProductsContext.Provider>
  );
}

export default ProductsContext;
