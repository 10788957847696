import React from "react";

export default function Header({
  backgroundImage,
  header,
  headerUnderstatement,
}) {
  return (
    <header>
      <div
        className="pt6 pt6-m pt6-l pb6-l cover bg-left bg-center-l background"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          minHeight: "50vh",
        }}
      >
        <div className="tc pv1 pa4-ns bg-white-80 mw6 mw8-l center mt1 mt1-l ">
          <h2 className=" mv2 f5 f3-l fw6">{header}</h2>
          {headerUnderstatement ? (
            <p className="fw4 white-80 mt3 mb2 black text f-18">
              {headerUnderstatement}
            </p>
          ) : null}
        </div>
      </div>
    </header>
  );
}
