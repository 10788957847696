import React from "react";
import ContactForm from "../components/ContactForm";
import ImpressumPage from "./ImpressumPage.json";
import { Helmet } from "react-helmet";

export default function Impressum() {
  const {
    impressum_header,
    company_name,
    conduct_of_company,
    managers_of_company,
    adresse,
    street,
    plz_and_city,
    telefon,
    telefax,
    handelsregister,
    sitz_der_gmbh,
    ust_id_nr,
    bankverbindungen_header,
    sparkasse_erlangen,
    commerzbank_erlangen,
    page_name,
  } = ImpressumPage;
  return (
    <>
      <Helmet>
        <title>{page_name}</title>
        <meta name="description" content={company_name} />
      </Helmet>
      <div>
        <section className="mw8 center pt5 ph3">
          <h2 className="  gray-color f-26 fw2 pt3">{impressum_header}</h2>
          <div className="columns1">
            <div>
              <p className="text">{company_name}</p>
              <p className="text">
                <b>{conduct_of_company}</b>
                {managers_of_company}
              </p>
              <p className="text">
                <b>{adresse}</b>
              </p>
              <p className="text">
                {street}
                <br />
                {plz_and_city}
              </p>
              <p className="text">
                {telefon} <br />
                {telefax}
              </p>

              <p className="text">
                {handelsregister} <br /> {sitz_der_gmbh}
                <br />
                {ust_id_nr}
              </p>

              <p className="text">
                <b>{bankverbindungen_header}</b>
              </p>
              <p className="text">
                {sparkasse_erlangen}
                <br /> {commerzbank_erlangen}
              </p>
            </div>
          </div>
        </section>
        <ContactForm />
      </div>
    </>
  );
}
