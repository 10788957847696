import React from "react";
import filter1 from "../images/filter1.svg";
import filter2 from "../images/filter2.svg";
import filter3 from "../images/filter3.svg";
import filter4 from "../images/filter4.svg";

function Filter(props) {
  const { productCategoriesList } = props;
  if (!productCategoriesList) {
    return null;
  }
  const filters = [filter1, filter2, filter3, filter4];
  return (
    <div className="flex  flex-column flex-row-l flex-wrap justify-between ">
      {productCategoriesList.length > 0 &&
        productCategoriesList.map((ref, index) => (
          <div
            className={` mr0 mr2-l ml2-l w-100 ${
              index + 1 === productCategoriesList.length ? "" : "w-30-l"
            }`}
            style={{ flexGrow: "1" }}
            key={index}
          >
            <div className="flex  flex-column ph3  ">
              <small>
                <img src={filters[index]} className="w3 pr2 v-mid" alt="" />

                {ref.filter_header}
              </small>

              <ul className="pl0 flex flex-column lh-copy flex-wrap  mw8 height-max height-99">
                {ref.subcategories.map((filterText, indexing) => (
                  <li className="list " key={indexing}>
                    <label className="ml3 mr3" htmlFor="filter">
                      <input
                        className="mr3 filter"
                        type="checkbox"
                        id={filterText.filter_text}
                        value={filterText.filter_text}
                        checked={
                          props.filters.indexOf(filterText.filter_text) !== -1
                        }
                        onChange={(e) => props.handleInputChange(e, ref)}
                      />
                      <label htmlFor={filterText.filter_text}>
                        {filterText.filter_text}{" "}
                        {filterText.products_length > 0 &&
                          `(${filterText.products_length})`}
                      </label>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Filter;
