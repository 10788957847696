import React from "react";
import email from "../images/email.svg";
import tel from "../images/iphone.svg";
import location from "../images/location.svg";

const prevententerFn = (e) => {
  e.key === "Enter" && e.preventDefault();
};

function ContactForm({ initialText = "" }) {
  return (
    <section className="mw8 center ma3 mb5 mt5 flex justify-center">
      <div className="w-90 w-60-l bg-light-gray">
        <h1
          className="center tl text-header fw1 ph4 f3 f2-ns mw7 pt2"
          style={{ fontSize: "26px", fontWeight: "bold" }}
        >
          Haben Sie Fragen zu uns, unseren Produkten oder zu individuellen
          Lösungen? Kontaktieren Sie uns!
        </h1>
        <form className="flex flex-column center" action="/email" method="post">
          <label
            className="ph3 pt3 pb4 flex flex-column "
            id="contact"
            htmlFor="Contact"
          >
            <label
              className="mh1 mv0 gray-color f-18 pb2 pt2 link inline-flex"
              href="tel:+49 (0) 9132 750440"
              htmlFor="phone"
            >
              <img src={tel} className="w1 h1 ph3" alt="" />
              <a
                className="f5 f4-ns link gray-color"
                href={`tel:+499132750440`}
              >
                +49 (0) 9132 750440
              </a>
            </label>
            <label
              className="mh1 mv0 gray-color f-18 pb2 pt2 link inline-flex"
              htmlFor="address"
            >
              <img src={location} className="w1 h1 ph3" alt="" />
              <p className=" f5 f4-ns ma0">
                Peter-Fleischmann-Str 30,
                <br />
                D-91074 Herzogenaurach
              </p>
            </label>
            <label
              htmlFor="email"
              className="mh1 mv0 gray-color f-18 pb2 pt2 link inline-flex"
              href="mailto:sales@schuster-elektronik.de?Subject=Schuster%20Website"
            >
              <img src={email} className="w1 h1 ph3" alt="" />
              <a
                className="f5 f4-ns link gray-color"
                href={`mailto:sales@schuster-elektronik.de`}
              >
                sales@schuster-elektronik.de
              </a>
            </label>
          </label>
          <label className="flex flex-column pv3" htmlFor="name">
            <input
              name="name"
              type="text"
              placeholder="Ihr Name*"
              className="outline-0 bn mh3 pa3"
              required="required"
              onKeyDown={prevententerFn}
            />
          </label>
          <label className="flex flex-column pv3" htmlFor="email">
            <input
              name="email"
              type="email"
              placeholder={"Ihre E-Mail-Adresse*"}
              className="outline-0 bn mh3 pa3"
              required="required"
              onKeyPress={prevententerFn}
            />
          </label>
          <label className="flex flex-column  pv3" htmlFor="message">
            <textarea
              name="content"
              placeholder="Ihre Nachricht*"
              style={{ height: "200px" }}
              className="outline-0 bn mh3 pa3"
              required="required"
              defaultValue={initialText}
            />
          </label>
          <div className="tl ph3 pt3 pb3 ">
            <input
              type="submit"
              value="Senden"
              className="bn br2 bg-schuster db pv3 ph4 b link tc w-100 hover-schuster-inverted-white"
            />
          </div>
        </form>
      </div>
    </section>
  );
}

export default ContactForm;
