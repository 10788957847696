import React from "react";
import { Link } from "react-router-dom";
import Markdown from "./Markdown";

function substring_last_index(myString) {
  myString = myString && myString.substring(0, 200);

  let index = myString && myString.lastIndexOf(" ");

  let outputString = myString && myString.substring(0, index);
  return outputString + "...";
}

export default function ProductItem({ lang, product }) {
  const { bild, urlpart, product_name, internetbeschreibung, internetbeschreibung_en } = product;

  return (
    <Link to={`/${lang}/product/${urlpart}`} className="link">
      <article
        style={{ background: "rgba(244, 244, 244, 0.47)" }}
        key={product_name}
        className="mb4 br bl bt b--black-20 bg-white one-edge-shadow center w-8 mw-18 article h-16"
      >
        <div className="tc">
          {bild ? (
            <img
              src={bild}
              style={{ objectFit: "contain" }}
              className="minheight-13  w-100 dib"
              alt=""
            />
          ) : null}
          <h1 className="f5 h2 black article-name mb3">
            {product_name}
          </h1>
          <hr className="mw3 bb bw1 b--black-10" />
        </div>
        <Markdown
          style={{ minHeight: "130px" }}
          className="internetbeschreibung lh-copy measure center f6 black-70 h-25 ph3 pv3 black"
          value={substring_last_index(lang === "de" ? internetbeschreibung : internetbeschreibung_en)}
        />
        <footer
          style={{ color: "#04989a" }}
          className="bt bb b--black-20 pa2 w-bold"
        >
          <p className="flex justify-between">
            <span>Details</span>
          </p>
        </footer>
      </article>
    </Link>
  );
}
