import { useEffect, useState } from "react";
import axios from "axios";

async function fetchData(setData) {
  try {
    let products = [];
    const response = await axios.get("/products/data.json");
    if (response.status === 200) {
      response.data.forEach((item) => {
        products.push({
          id: item.id,
          urlpart: item.urlpart,
          product_title: item.product_title,
          title: item.title,
          ...item.fields,
        });
      });
    } else {
      throw new Error(response.status.toString());
    }
    setData(products);
  } catch (err) {
    console.log(err);
  }
}

const useFetchProducts = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData(setData);
  }, []);

  return { data };
};

export default useFetchProducts;
