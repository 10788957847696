import React from "react";
import ContactForm from "../components/ContactForm";
import DataPrivacyPage from "./DataPrivacyPage.json";
import { Helmet } from "react-helmet";

export default function DataPrivacy() {
  const {
    datenschutz_header,
    data_privacy_introduction,
    data_privacy_points,
    ankunftsrecht_header,
    ankunftsrecht_description,
    legal_advice,
    legal_advice_description,
    page_name,
  } = DataPrivacyPage;

  return (
    <>
      <Helmet>
        <title>{page_name}</title>
        <meta name="description" content={ankunftsrecht_description} />
      </Helmet>
      <section className="mw8 center pt5 ph3">
        <h2 className="  gray-color f-26 fw2 pt3">{datenschutz_header}</h2>
        <p className="text pv2">{data_privacy_introduction}</p>
        <ol className="text">
          {data_privacy_points.map((item, index) => (
            <li key={index}>{item.data_privacy_point}</li>
          ))}
        </ol>
        <p className="text mv0">{ankunftsrecht_header}</p>
        <p className="text mv0">{ankunftsrecht_description}</p>

        <h2 className="  gray-color f-26 fw2 pt3">{legal_advice}</h2>
        {legal_advice_description.map((item, index) => (
          <div className="pv2" key={index}>
            <p className="text  mv0 pv2">{item.legal_advice_header}</p>
            <p className="text  mv0 ">{item.legal_advice_description}</p>
          </div>
        ))}
        <ContactForm />
      </section>
    </>
  );
}
