import { createContext } from "react";

// function doFetch(section, language) {
//   return fetch(
//     `https://schusterelektronik.kubernetes.netsyno.com/wp-json/wp/v2/${section}?lang=${language}&per_page=33`
//   ).then((response) => response.json());
// }

const LanguageContext = createContext('de');
export default LanguageContext;
