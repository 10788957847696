import React from "react";
import { Helmet } from "react-helmet";

const PAGE_NAME = "Not Found";
export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>{PAGE_NAME}</title>
      </Helmet>
      <div className="pt7">404 Not Found</div>
    </>
  );
}
