import React from "react";
import Header from "../components/header";
import ContactForm from "../components/ContactForm";

import AboutPage from "./AboutPage.json";
import { Helmet } from "react-helmet";
// import Markdown from "../components/Markdown";

export default function About() {
  const {
    backgroundImage,
    header,
    headerUnderstatement,
    // meta_navigation_header,

    // ref_header,
    // reference_text,
    // reference_item,
    // partner_header,
    // partner_text,
    // item_partner,
    history_item,
    page_name,
  } = AboutPage;

  return (
    <>
      <Helmet>
        <title>{page_name}</title>
      </Helmet>
      <div>
        <Header
          backgroundImage={backgroundImage}
          header={header}
          headerUnderstatement={headerUnderstatement}
        />
        {/* <div className="  flex flex-row flex-wrap justify-center bg-green-schuster">
        <div className="flex flex-column flex-row-l ph3  tl tc-l">
          <p className=" white  dib mh4 tc roboto-condensed hover-under  outline-0 no-underline pv3">
            {meta_navigation_header}
          </p>
        </div>
      </div> */}
        {/* <div style={{ padding: "20px" }}>
        <Markdown value={body} />
      </div> */}

        {/* <section className=" center pa3" id="#refs">
          <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">{ref_header}</h1>
          <div className="columns1 columns2 mw7 center ph4 ph0-ns ">
            <p className="mv0 text">{reference_text}</p>
          </div>
          <div className="flex flex-row  justify-center  mb5 flex-wrap">
            {reference_item.map((ref, index) => (
              <div
                className="w4 h4 br-100 flex flex-column  mh3 mv5 mh4-l "
                key={index}
              >
                <img
                  className="relative"
                  src={ref.reference_image}
                  alt=""
                  style={{ bottom: "-5px" }}
                />
                <h3
                  className=" mr2 gray-color relative tc w4 fw2 link"
                  style={{ bottom: "-5px" }}
                >
                  {ref.reference_name}
                </h3>
              </div>
            ))}
          </div>
        </section>
        <section className="mw8 center pa3" id="#partner">
          <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
            {partner_header}
          </h1>
          <div className="columns1 columns2 mw7 center  ph4 ph0-ns">
            <p className="mv0 text">{partner_text}</p>
          </div>
          <div className="flex flex-row flex-wrap justify-center mb5 ">
            {item_partner.map((partner, index) => (
              <div
                className="w4 h4 br-100 flex flex-column  mh3 mv5 mh4-l"
                key={index}
              >
                <div>
                  <img
                    className="relative"
                    src={partner.image_partner.url}
                    alt=""
                    style={{ bottom: "-5px" }}
                  />
                </div>
                <h3
                  className=" mr2 gray-color relative tc w4 fw2"
                  style={{ bottom: "-5px" }}
                >
                  {partner.partner_name}
                </h3>
              </div>
            ))}
          </div>
        </section> */}

        <section className="mw8 center pa3" id="#geschichte">
          <article>
            {history_item.map((item, index) => (
              <div
                className="db pv4  ph0-m ph0-l no-underline black "
                key={index}
              >
                <div className="flex flex-column flex-row-ns pv3 ">
                  <div
                    className="flex lh-copy mr0 pa0 mr5-l pa3-l center mb3 mb0-ns"
                    style={{ margin: "auto" }}
                  >
                    <p
                      className="f3 br-100  bg-light-gray  dib text-header b"
                      style={{ padding: "2.5rem 2rem" }}
                    >
                      {item.year}
                    </p>
                  </div>
                  <div className=" pa3 w-100 center">
                    <h2 className=" f-26 tc tl-l">{item.title}</h2>
                    <p className="text lh-copy">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </article>
        </section>

        <ContactForm />
      </div>
    </>
  );
}
