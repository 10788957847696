import React from "react";

function SuccessSend() {
  return (
    <article
      className="mw6  bg-white  pt5 pa3 pa4-ns   center"
      style={{
        paddingTop: "100px",
      }}
    >
      <div className="tc">
        <h1 className="f4">Ihre Anfrage wurde erfolgreich abgeschickt. </h1>
        <hr className="mw3 bb bw1 b--black-10" />
      </div>
      <div className="flex">
        <a
          href="/"
          className="dib pv3 ph3 bg-schuster-inverted b br2 mv2  outline-0 link  tc w-40 center"
        >
          Startseite
        </a>
      </div>
    </article>
  );
}

export default SuccessSend;
