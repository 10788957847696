import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  ScrollRestoration,
} from "react-router-dom";
import AppRoutes from "./AppRoutes";

export const AllRoutes = (
  <>
    <Route
      path="/:lang/*"
      element={
        <>
          <ScrollRestoration />
          <AppRoutes />
        </>
      }
    />
    <Route exact path="/" element={<Navigate replace to="/de/home" />} />
  </>
);

export const routes = createRoutesFromElements(AllRoutes);

const router = createBrowserRouter(routes);

export default router;
