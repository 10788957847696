import React from "react";
import { Link } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

function FailSend() {
  const lang = useLanguage();

  return (
    <article
      className="mw6  bg-white  pt5 pa3 pa4-ns   center"
      style={{ paddingTop: "100px" }}
    >
      <div className="tc">
        <h1 className="f4">
          Ihre Anfrage wurde leider nicht erfolgreich abgeschickt.{" "}
        </h1>
        <hr className="mw3 bb bw1 b--black-10" />
      </div>
      <p className="lh-copy measure center f6 black-70">
        <Link className="b pointer link " to={`/${lang}/contact`}>
          Klicken Sie hier um auf die Kontaktseite zur&uuml;ck zu gelangen.
        </Link>
      </p>
    </article>
  );
}

export default FailSend;
